import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { renderField } from "../../../components/forms";
import { toAbsoluteUrl } from "../../../utils";
import "./signup.scss";
import { strategicRegistrationValidation as validate } from "../../../components/validations/signIn";
import { toast } from "react-toastify";
import { strategicRegister } from "../../../actions/registration";
import Tookit from "../../../components/svg/tookit";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const onSubmit = (values) => {
    if (!isChecked) {
      toast.error("You must agree with the terms and conditions");
      return;
    }
    const data = {
      userName: values.strategicpartneruserName,
      name: values.strategicname,
      ownerName: values.strategicownername,
      emailAddress: values.strategicemailaddress,
      password: values.password,
      confirmPassword: values.Confirmpassword,
    };
    dispatch(strategicRegister(data))
      .then((res) => {
        navigate("/strategic_signin");
        toast.success(res.message);
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      <section className="section-div signup-section">
        <div className="d-flex align-items-center min-h100">
          <div className="col-6 none-mobile">
            <div>
              <figure className="text-center figure-img">
                <img
                  src={toAbsoluteUrl("/images/giving partner signIn.png")}
                  alt="left-img"
                />
              </figure>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="form-box">
                  <figure className="mainLogo">
                    <img src={toAbsoluteUrl("/images/logo.svg")} alt="logo" />
                  </figure>
                  <span className="signHeader">
                    Giving Partner Registration
                  </span>
                  <ul className="input-list">
                    <li>
                      <span className="tooltip-info" data-tooltip-id="username">
                        <Tookit />
                      </span>
                      <ReactTooltip
                        id="username"
                        place="top"
                        variant="success"
                        content="Only letters can be used (no number, spaces or special characters), and the Username should incorporate an abbreviation of the name of your organization."
                      />
                      <Field
                        name="strategicpartneruserName"
                        type="text"
                        placeholder="Giving Partner Username"
                        component={renderField}
                        className="form-grp user"
                        inputclass="input-box"
                      />
                    </li>
                    <li>
                      <span className="tooltip-info" data-tooltip-id="name">
                        <Tookit />
                      </span>
                      <ReactTooltip
                        id="name"
                        place="top"
                        variant="success"
                        content="This must be the full name of the legal entity or organization.  You should include the form of the organization (such as Inc. Corp. LLC, etc.) or description that differentiates it from similar organizations (e.g., a city, etc.)."
                      />
                      <Field
                        name="strategicname"
                        type="text"
                        placeholder="Giving Partner Name"
                        component={renderField}
                        className="form-grp build"
                        inputclass="input-box"
                      />
                    </li>
                    <li>
                      <span className="tooltip-info" data-tooltip-id="ownerName">
                        <Tookit />
                      </span>
                      <ReactTooltip
                        id="ownerName"
                        place="top"
                        variant="success"
                        content="This should be the name of the individual who will be administering the Giving Partner’s Rosi account, events, communications/posts and have access to the Giving Partner’s dashboard."
                      />
                      <Field
                        name="strategicownername"
                        type="text"
                        placeholder="Giving Partner Representative Name"
                        component={renderField}
                        className="form-grp user"
                        inputclass="input-box"
                      />
                    </li>
                    <li>
                      <span className="tooltip-info" data-tooltip-id="email">
                        <Tookit />
                      </span>
                      <ReactTooltip
                        id="email"
                        place="top"
                        variant="success"
                        content="This should be the email address of the Giving Partner’s Representative or a general email address of the Giving Partner that it’s Representative has access to.  All emails from Rosi will be sent to this email address.  It must be used to login to the Giving Partner’s Rosi account.  NOTE: If the Representative intends to register for an individual Rosi account, they will not be able to use the same email address. "
                      />
                      <Field
                        name="strategicemailaddress"
                        type="text"
                        placeholder="Giving Partner Representative Email Address"
                        component={renderField}
                        className="form-grp username"
                        inputclass="input-box"
                      />
                    </li>
                    <li>
                      <span className="tooltip-info" data-tooltip-id="password">
                        <Tookit />
                      </span>
                      <ReactTooltip
                        id="password"
                        place="top"
                        variant="success"
                        content="These must be matching entries and consist of: 
                        • Eight (8) Characters
                        • One (1) Capital letter
                        • One (1) Lower case letter
                        • One (1) number 
                        • One (1) special character"
                      />
                      <Field
                        name="password"
                        type="password"
                        placeholder="Password"
                        component={renderField}
                        className="form-grp password"
                        inputclass="input-box"
                      />
                    </li>
                    <li>
                      <span className="tooltip-info" data-tooltip-id="confirmPassword">
                        <Tookit />
                      </span>
                      <ReactTooltip
                        id="confirmPassword"
                        place="top"
                        variant="success"
                        content="These must be matching entries and consist of: 
                        • Eight (8) Characters
                        • One (1) Capital letter
                        • One (1) Lower case letter
                        • One (1) number 
                        • One (1) special character"
                      />
                      <Field
                        name="Confirmpassword"
                        type="password"
                        placeholder="Confirm Password"
                        component={renderField}
                        className="form-grp password"
                        inputclass="input-box"
                      />
                    </li>
                  </ul>
                  <div className="address-terms">
                    <label className="check-label rememberReg">
                      <h5>
                        I agree to the{" "}
                        <a
                          href={`${process.env.REACT_APP_WEB_PUBLIC_URL}terms`}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Terms of Use{" "}
                        </a>
                        of Rosi Giving, Inc.
                      </h5>
                      <div>
                        <input
                          type="checkbox"
                          defaultChecked={isChecked}
                          onClick={() => setIsChecked((prev) => !prev)}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                  <div>
                    <button type="submit" className="link-btn">
                      Click here to Register
                    </button>
                    <Link
                      to="/strategic_signin"
                      className="link-btn strategicButton"
                    >
                      Click here if already a Giving Partner
                    </Link>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
        <strong>
          © {new Date().getUTCFullYear()} Produced by Rosi Giving, Inc.
        </strong>
      </section>
    </>
  );
};

export default Register;
